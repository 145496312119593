@import 'settings';

body{
	width: 100%;
	height: 100%;
	padding: 0;
	margin: 0;
}

video{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: cover;
}
.video-overlay{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: -moz-radial-gradient(center, ellipse cover, rgba(0,0,0,0.3) 0%, rgba(0,0,0,0.95) 100%);
	background: -webkit-radial-gradient(center, ellipse cover, rgba(0,0,0,0.3) 0%,rgba(0,0,0, 0.95) 100%);
	background: radial-gradient(ellipse at center, rgba(0,0,0,0.3) 0%,rgba(0,0,0,0.95) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#b3000000', endColorstr='#000000',GradientType=1 );
}
.logo{
	position: absolute;
	top: 10px;
	left: 20px;

	font: 400 16px/45px $sanspro;
	// letter-spacing: -0.025em;

	color: rgba(255, 255, 255, 0.25);
	user-select: none;

	@media screen and (min-width: 540px){
		top: 20px;
		left: 40px;
	}
}
.logo a{
	font: 45px/45px $champ;
	color: $orange;
	text-decoration: none;
	margin-right: 7px
}

.mission{
	position: absolute;
	bottom: 40px;
	right: 10px;
	left: 35px;

	max-width: 450px;

	font: 700 22px/28px $sanspro;
	color: rgba(255, 255, 255, 1);

	&::before{
		position: absolute;
		top: 7px;
		left: -5px;
		content: '';
		display: block;
		height: 50px;
		width: 1px;


		background: $orange;
	}

	@media screen and (min-width: 680px){
		left: auto;
		bottom: 40px;
		right: 40px;
		font: 700 28px/32px $sanspro;
	}
}

blockquote{
	margin: 0;
}

.mission-title{
	color: rgba(255, 255, 255, 0.4);
	position: absolute;
	top: 0;
	left: -40px;
	transform: translateY(25px) rotate(-90deg);
	// transform-origin: right;

	font: 400 14px/14px $sanspro;
	// background: red;
}

.mission-title::after{
	// content: '';
	position: absolute;
	left: 10px;
	top: 15px;

	background: orange;
	height: 45px;
	width: 2px;

	transform: rotate(-90deg)
}

.soon{
	position: absolute;
	bottom: 10px;
	left: 50%;

	font: 14px/14px $sanspro;
	text-transform: uppercase;

	user-select: none;
	letter-spacing: 1px;

	color: rgba(255, 255, 255, 0.7);

	transform: translate(-50%, 0);

	@media screen and (min-width: 680px){
		left: 40px;
		bottom: 40px;
		transform: translate(0, 0);
	}
}

.social{
	position: absolute;
	top: 20px;
	right: 40px;

	display: flex;

	a{
		position: relative;
		display: block;
		height: 30px;
		width: 30px;

		&:hover{
			svg{
				fill: #FFF;
			}
		}

		&.social-fb{
			svg{
				widh: 16px;
				height: 16px;
			}
		}
	}
	
	

	svg{
		position: absolute;
		top: 50%;
		left: 50%;
		display: block;
		width: 18px;
		height: 18px;
		fill: rgba(255, 255, 255, 0.8);
		transform: translate(-50%, -50%);

		transition: fill .2s ease-in-out;
	}


}