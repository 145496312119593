@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:400,600,700');

@font-face {
    font-family: 'Champagne & Limousines';
    src: local('Champagne & Limousines'), local('Champagne&Limousines'),
        url('./fonts/ChampagneLimousines.woff2') format('woff2'),
        url('./fonts/ChampagneLimousines.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Champagne & Limousines';
    src: local('Champagne & Limousines Italic'), local('Champagne&Limousines-Italic'),
        url('./fonts/ChampagneLimousines-Italic.woff2') format('woff2'),
        url('./fonts/ChampagneLimousines-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Champagne & Limousines';
    src: local('Champagne & Limousines Bold'), local('Champagne&Limousines-Bold'),
        url('./fonts/ChampagneLimousines-Bold.woff2') format('woff2'),
        url('./fonts/ChampagneLimousines-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Champagne & Limousines';
    src: local('Champagne & Limousines Bold Italic'), local('Champagne&Limousines-BoldItalic'),
        url('./fonts/ChampagneLimousines-BoldItalic.woff2') format('woff2'),
        url('./fonts/ChampagneLimousines-BoldItalic.woff') format('woff');
    font-weight: bold;
    font-style: italic;
}

$sanspro: 'Source Sans Pro';
$champ: 'Champagne & Limousines';

$orange: #ff510d;